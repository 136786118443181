
import { getAction, getByIdAction, postAction, putAction, deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/common/commonUnitGroup/v1'
//调用 import { getTableLists } from "@/api/reportFrom/repost";
const getCommonUnitGroupPageListApi = (params) => getAction(baseURL + '/pageList', params);

const getCommonUnitGroupListApi = (params) => getAction(baseURL + '/list', params);

const getCommonUnitGroupSelectByIdApi = (params) => getByIdAction(baseURL + '/selectById', params);
const addCommonUnitGroupDataApi = (params) => postAction(baseURL + '/add', params);
const editCommonUnitGroupDataApi = (params) => putAction(baseURL + '/edit', params);
const deleteCommonUnitGroupByIdApi = (params) => deleteByIdAction(baseURL + '/deleteById', params);
export {
    getCommonUnitGroupPageListApi,
    getCommonUnitGroupSelectByIdApi,
    addCommonUnitGroupDataApi,
    editCommonUnitGroupDataApi,
    deleteCommonUnitGroupByIdApi,
    getCommonUnitGroupListApi
}