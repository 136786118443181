
import { getAction,getByIdAction,postAction,putAction,deleteByIdAction} from '@/api/common/request'
const baseURL = 'xun/common/commonUnit/v1'
//调用 import { getTableLists } from "@/api/reportFrom/repost";
const getCommonUnitPageListApi=(params)=>getAction(baseURL+'/pageList',params);
const getCommonUnitListApi=(params)=>getAction(baseURL+'/list',params);
const getCommonUnitSelectByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);
const addCommonUnitDataApi=(params)=>postAction(baseURL+'/add',params);
const editCommonUnitDataApi=(params)=>putAction(baseURL+'/edit',params);
const deleteCommonUnitByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);
export{
    getCommonUnitPageListApi,
    getCommonUnitSelectByIdApi,
    addCommonUnitDataApi,
    editCommonUnitDataApi,
    deleteCommonUnitByIdApi,
    getCommonUnitListApi
}