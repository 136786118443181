<template>
  <!-- <el-dialog :title="title" :visible.sync="dialogVisible" :before-close="handleClose"> -->
  <el-drawer
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="demo-drawer"
    :before-close="handleClose"
    ref="drawer"
    size="30%"
  >
    <el-form :model="saveUpdateFrom" label-width="100px" ref="saveUpdateFrom" :rules="rules">
      <el-row :gutter="24">
        <el-col>
          <el-form-item label="单位" prop="name">
            <el-input v-model="saveUpdateFrom.name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col>
          <el-form-item label="符号英文" prop="notate">
            <el-input v-model="saveUpdateFrom.notate" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col>
          <el-form-item label="分组" prop="convertGroupIds">
            <el-select
              v-model="saveUpdateFrom.convertGroupIds"
              filterable
              placeholder="请选择"
              value-key="uniqueId"
              @change="getGroupButton"
            >
              <el-option
                v-for="item in unitGroupList"
                :key="item.uniqueId"
                :label="item.name"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col>
          <el-form-item label="标准单位" prop="unitName">
            <el-input v-model="saveUpdateFrom.unitName" disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col>
          <el-form-item label="换算比例" prop="convertScale">
            <el-input-number v-model="saveUpdateFrom.convertScale" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="10">
          <el-form-item label="经常使用" prop="isOften">
            <el-switch
              v-model="saveUpdateFrom.isOften"
              active-color="#ff4949"
              inactive-color="#13ce66"
              active-text="否"
              inactive-text="是"
              :active-value="2"
              :inactive-value="1"
            ></el-switch>
          </el-form-item>
        </el-col>

        <el-col :span="14">
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="saveUpdateFrom.status">
              <el-radio :label="1">正常</el-radio>
              <el-radio :label="2">失效</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col>
          <el-form-item label="描述" prop="describes">
            <el-input v-model="saveUpdateFrom.describes" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="saveUpdateFrom.remark" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="demo-drawer__footer" style="margin: 0px 50px">
      <el-button type="primary" @click="editSaveButton">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
    <!-- </el-dialog> -->
  </el-drawer>
</template>
<script>
import { getCommonUnitGroupListApi } from "@/api/system/unit/unitGroup.js";
import {
  addCommonUnitDataApi,
  editCommonUnitDataApi,
  getCommonUnitSelectByIdApi
} from "@/api/system/unit/unit.js";
export default {
  data() {
    return {
      dialogVisible: false,
      ids: null,
      types: 1,
      editShow: true,
      title: null,
      saveUpdateFrom: {
        status: "1"
      },
      // 表单校验
      rules: {
        convertGroupIds: [
          { required: true, message: "分组名称不能为空", trigger: "blur" }
        ]
      },
      unitGroupList: []
    };
  },
  methods: {
    //打开弹窗 1新增 2编辑
    show(row, type) {
      this.unitGroupDataButton(row);
      this.dialogVisible = true;
      this.types = type;
      this.initData(row);
      if (type == 1) {
        this.title = "新增";
      }
      if (type == 2) {
        this.title = "编辑";
        this.ids = row.id;
        this.selectDataById(row);
      }
    },
    selectDataById(row) {
      getCommonUnitSelectByIdApi(row.id).then(res => {
        this.saveUpdateFrom = res.result;
        console.log(res.result.convertGroupId);
        this.saveUpdateFrom.convertGroupIds = {
          uniqueId: res.result.convertGroupId
        };
        this.saveUpdateFrom.unitName=this.unitGroupList.find(t => t.uniqueId == res.result.convertGroupId).unitName
      });
    },
    cancel() {
      this.saveUpdateFrom = {
        status: "1"
      };
      this.dialogVisible = false;
      //调用父组件的查询
      this.$parent.selectListButton();
      this.ids = null;
      this.$parent.id = null;
      this.$parent.questionnaireId = null;
      this.$refs["saveUpdateFrom"].resetFields();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.cancel();
        })
        .catch(_ => {});
    },
    editSaveButton() {
      console.log(this.saveUpdateFrom);
      this.saveUpdateFrom.convertGroupId = this.saveUpdateFrom.convertGroupIds.uniqueId;
      this.$refs["saveUpdateFrom"].validate(valid => {
        if (valid) {
          if (this.types == 1) {
            //新增成功
            addCommonUnitDataApi(this.saveUpdateFrom).then(res => {
              this.cancel();
            });
          } else {
            //修改成功
            editCommonUnitDataApi(this.saveUpdateFrom).then(res => {
              this.cancel();
            });
          }
        }
      });
    },
    initData(row) {
      if (null != row) {
        console.log(row);

        this.$nextTick(() => {
          this.saveUpdateFrom.convertGroupIds = {
            uniqueId: row.convertGroupId
          };
        });
        this.saveUpdateFrom.unitName = row.unitName;
      }
    },
    unitGroupDataButton(row) {
      getCommonUnitGroupListApi().then(res => {
        this.unitGroupList = res.result;
      });
    },
    getGroupButton(val) {
      this.saveUpdateFrom.unitName = val.unitName;
      //清空重新绑定问题
      this.$forceUpdate();
    }
  }
};
</script>
<style lang="less" scoped>
</style>