var toggleSearchStatus=1;
<template>
  <div class="tripIndex">
    <!-- 导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>生活管理</el-breadcrumb-item>
      <el-breadcrumb-item>旅游管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs v-model="activeName" @tab-click="handleClickTab">
      <el-tab-pane label="分组列表" name="first" :key="'first'">
        <div>
          <el-form layout="inline" :model="queryParams" :inline="true">
            <el-row :gutter="24">
              <el-col :md="6" :sm="8">
                <el-form-item label="记录月份">
                  <el-input
                    placeholder="请输入"
                    style="width:60%"
                    v-model="queryParams.recordData"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <template v-if="toggleSearchStatus">
                <el-col :md="6" :sm="8">
                  <el-form-item label="子表状态">
                    <el-input placeholder="请输入" style="width:60%"></el-input>
                  </el-form-item>
                </el-col>
              </template>
              <el-col :md="!toggleSearchStatus && 20|| 20" :sm="24">
                <span :style="handleToggleSearch && { float: 'right', overflow: 'hidden' } || {} ">
                  <el-button
                    type="primary"
                    @click="selectListButton()"
                  >{{toggleSearchStatus ? '高级查询' : '搜索' }}</el-button>
                  <el-button type="primary">重置</el-button>
                  <a @click="handleToggleSearch">
                    {{toggleSearchStatus ? '收起' : '高级查询'}}
                    <el-icon :type="toggleSearchStatus ? 'up' : 'down'" />
                  </a>
                </span>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!-- 按钮区 -->
        <el-row :gutter="20" style="margin:9px 2px">
          <el-button type="success" icon="el-icon-add" size="min" @click="addFromButton">新增</el-button>
          <el-button type="success" icon="el-icon-add" size="min" @click="editFromButton">编辑</el-button>
          <el-button type="danger" icon="el-icon-add" size="min" @click="delectFromButton">删除</el-button>
        </el-row>

        <el-table :data="dataColumnList" :border="true" @row-dblclick="dataBackFillGoods">
          <el-table-column label="选择" header-align="center" align="center">
            <template slot-scope="scope">
              <el-radio
                class="radio"
                v-model="questionnaireId"
                :label="scope.row.id"
                @click.native.stop.prevent="getCurrentRow(scope.row)"
              >&nbsp;</el-radio>
            </template>
          </el-table-column>
          <!-- //这里做循环 -->

          <el-table-column label="分组名称" align="center" prop="name" />
          <el-table-column label="分组描述" align="center" prop="describes" />
          <el-table-column label="标准单位" align="center" prop="unitName">
            <template slot-scope="scope">
              <el-tag type="danger" v-if="scope.row.unitName==null">未配置</el-tag>
              <el-tag type="success" v-else>{{scope.row.unitName}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" prop="status" />
          <el-table-column label="创建时间" align="center" prop="createdTime" />
          <el-table-column label="操作" header-align="center" align="center">
            <template slot-scope="scope">
              <!-- <el-button
                @click.native.stop.prevent="addDetailButton(scope.row)"
                size="small"
                round
              >添加详情</el-button>-->
              <el-button
                @click.native.stop.prevent="selectUnitButton(scope.row)"
                size="small"
                round
              >查看单位</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <pagination
      :total="queryParams.total"
      :page-size.sync="queryParams.pageSize"
      :current-page.sync="queryParams.currentPage"
    />


      <el-tab-pane label="单位列表" ref="child" name="three" :key="'three'">
        <unitForm ref="unitForms"></unitForm>
      </el-tab-pane>
    </el-tabs>
    <addUnitGroup ref="addUnitGroupFrom" />
  </div>
</template>
<script>
import unitForm from "./unit";
import addUnitGroup from "./modules/addUnitGroup";
import { getCommonUnitGroupPageListApi } from "@/api/system/unit/unitGroup.js";
export default {
  components: {
    unitForm,
    addUnitGroup
  },
  data() {
    return {
      dataColumnList: [],
      activeName: "first",
      questionnaireId: "",
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      toggleSearchStatus: false,
      id: null,
      radio: "",
      uniqueId: null,
      templateSelection: {}
    };
  },
  created() {
    this.initDataList();
    this.selectListButton();
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.selectListButton();
    },
    "queryParams.pageSize"(val) {
      this.selectListButton();
    },
  },
  methods: {
    selectListButton() {
      getCommonUnitGroupPageListApi(this.queryParams).then(res => {
        this.dataColumnList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    initDataList() {},
    //再次点击取消单选选中状态
    getCurrentRow(row) {
      //this.questionnaireId 为上一次存储的id与这一次比对判断是否和上次点击的一致
      if (this.questionnaireId == row.id) {
        this.questionnaireId = null;
        this.id = null;
        this.templateSelection = {};
      } else {
        this.questionnaireId = row.id;
        this.id = row.id;
        this.templateSelection = row;
      }
    },
    addFromButton() {
      this.$refs.addUnitGroupFrom.show(null, 1);
    },
    editFromButton() {
      if (this.id == undefined || this.id == null)
        return this.$message.error("请选择一条数据");
      console.log(this.templateSelection);

      this.$refs.addUnitGroupFrom.show(this.templateSelection, 2);
    },
    delectFromButton() {},
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    //这里是双击事件
    dataBackFillGoods(row, event, column) {
      console.log("双击事件", row, event, column);
    },
    handleClickTab(tab, event) {
      if (tab.name == "first") {
        this.selectListButton();
      }
      if (tab.name == "three") {
        this.$refs.unitForms.queryParams = {};
        this.$refs.unitForms.selectListButton();
      }
      this.questionnaireId = null;
      this.id = null;
      this.templateSelection = {};
    },
    selectUnitButton(row) {
      this.activeName = "three";
      this.$refs.unitForms.queryParams.convertGroupId = row.uniqueId;
      this.$refs.unitForms.queryParams.unitName = row.unitName;
      this.$refs.unitForms.selectListButton();
    }
  }
};
</script>
<style lang="less" scoped>
</style>