<template>
  <!-- <el-dialog :title="title" :visible.sync="dialogVisible" :before-close="handleClose"> -->
  <el-drawer
    :title="title"
    :visible.sync="dialogVisible"
    custom-class="demo-drawer"
    :before-close="handleClose"
    ref="drawer"
  >
    <div class="demo-drawer__content">
      <el-form :model="saveUpdateFrom" label-width="100px" ref="saveUpdateFrom" :rules="rules">
        <el-row :gutter="24">
          <el-col>
            <el-form-item label="分组名称" prop="name">
              <el-input v-model="saveUpdateFrom.name" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col>
            <el-form-item label="分组描述" prop="describes">
              <el-input v-model="saveUpdateFrom.describes" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col>
            <el-form-item label="标准单位" prop="standardUnitId">
              <el-select
                v-model="saveUpdateFrom.standardUnitId"
                filterable
                placeholder="请选择"
                style="width:90%"
                   value-key="uniqueId"
              >
                <el-option
                  v-for="item in unitList"
                  :key="item.uniqueId"
                  :label="item.name"
                  :value="item.uniqueId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col>
            <el-form-item label="状态" prop="status">
              <el-radio v-model="saveUpdateFrom.status" :label="1">正常</el-radio>
              <el-radio v-model="saveUpdateFrom.status" :label="2">失效</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="demo-drawer__footer" style="margin: 0px 50px">
        <el-button type="primary" @click="editSaveButton">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </div>
    <!-- </el-dialog> -->
  </el-drawer>
</template>
<script>
import { getCommonUnitListApi } from "@/api/system/unit/unit.js";
import {
  addCommonUnitGroupDataApi,
  editCommonUnitGroupDataApi,
  getCommonUnitGroupSelectByIdApi
} from "@/api/system/unit/unitGroup.js";
export default {
  data() {
    return {
      dialogVisible: false,
      ids: null,
      types: 1,
      editShow: true,
      title: null,
      saveUpdateFrom: {
        status: "1"
      },
      // 表单校验
      rules: {
        name: [{ required: true, message: "分组名称不能为空", trigger: "blur" }]
      },
      unitList: []
    };
  },
  methods: {
    //打开弹窗 1新增 2编辑
    show(row, type) {
      this.dialogVisible = true;
      this.types = type;
      if (type == 1) {
        this.title = "新增";
      }
      if (type == 2) {
        this.initData(row);
        this.title = "编辑";
        this.ids = row.id;
        this.selectDataById();
      }
    },
    selectDataById() {
      getCommonUnitGroupSelectByIdApi(this.ids).then(res => {
        this.saveUpdateFrom = res.result;
      });
    },
    cancel() {
      this.saveUpdateFrom = {
        status: "1"
      };
      this.dialogVisible = false;
      //调用父组件的查询
      this.$parent.selectListButton();
      this.ids = null;
      this.$parent.id = null;
      this.$parent.questionnaireId = null;
      this.$refs["saveUpdateFrom"].resetFields();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.cancel();
        })
        .catch(_ => {});
    },
    editSaveButton() {
      this.$refs["saveUpdateFrom"].validate(valid => {
        if (valid) {
          if (this.types == 1) {
            //新增成功
            addCommonUnitGroupDataApi(this.saveUpdateFrom).then(res => {
              this.cancel();
            });
          } else {
            //修改成功
            editCommonUnitGroupDataApi(this.saveUpdateFrom).then(res => {
              this.cancel();
            });
          }
        }
      });
    },
    initData(row) {
      getCommonUnitListApi({convertGroupId:row.uniqueId}).then(res => {
        this.unitList = res.result;
      });
    }
  }
};
</script>
<style lang="less" scoped>
</style>